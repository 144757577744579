import { Injectable } from '@angular/core';
import { ENDPOINT_API } from '../terraform-vars';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Item } from '../models/item.model';
import { Log } from '../models/log.model';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getApplications(): Observable<Item[]> {
    return this.http.get<Item[]>(`${ENDPOINT_API}/applications`).pipe(
      map(items => {
        return items.map(item => {
          return {
            type: 'project',
            ...item
          } as Item
        });
      }),
      map(res => {
        return res.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
          else { return 1; }
        });
      })
    );
  }

  getInstances(): Observable<Item[]> {
    return this.http.get<Item[]>(`${ENDPOINT_API}/instances`).pipe(
      map(items => {
        return items.map(item => {
          return {
            type: 'instance',
            ...item
          } as Item
        });
      })
    );
  }

  getLogs(): Observable<Log[]> {
    return this.http.get<Log[]>(`${ENDPOINT_API}/logs`);
  }

  putApplications(projectId: string, newState: string, env: string): Observable<string> {
    return this.authService.user$.pipe(
      switchMap(user => {
        return this.http.put<any>(`${ENDPOINT_API}/applications/${projectId}`, { newState: newState, environment: env, user: `${user.given_name} ${user.family_name}` });
      })
    );
  }

  putInstances(instanceId: string, newState: string): Observable<string> {
    return this.authService.user$.pipe(
      switchMap(user => {
        return this.http.put<any>(`${ENDPOINT_API}/instances/${instanceId}`, { newState: newState, user: `${user.given_name} ${user.family_name}` });
      })
    );
  }
}
