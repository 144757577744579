import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Item } from '../models/item.model';
import { DataService } from '../services/data-service';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-instances',
  templateUrl: './instances.component.html',
  styleUrls: ['./instances.component.scss'],
})
export class InstancesComponent implements OnInit {
  instances: Item[];
  error: HttpErrorResponse;
  empty: Boolean;

  constructor(
    private data: DataService,
    private notifications: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.getInstances();
  }

  getInstances() {
    this.instances = undefined;
    this.empty = undefined;
    this.data.getInstances().subscribe(
      (instances: Item[]) => {
        this.error = undefined;
        this.instances = instances;
        if (instances.length == 0) {
          this.empty = true
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  putInstances(informations: any) {
    this.data.putInstances(informations.application, informations.state).subscribe(
      () => {
        this.error = undefined;
        this.notifications.notification$.next({
          message: 'Instance démarré',
          color: 'accent'
        });
        this.getInstances();
      },
      (error) => {
        this.error = error;
      }
    );
  }
}
