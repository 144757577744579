import { DOMAIN, CLIENT_ID, ENDPOINT_API } from '../app/terraform-vars';

export const environment = {
  production: false,
  authConfig: {
    domain: DOMAIN,
    clientId: CLIENT_ID,
    scope: 'all:all',
    audience: 'https://api.terega.fr/vm-manager',
    httpInterceptor: {
      allowedList: [
        {
          uri: `${ENDPOINT_API}/*`,
        }
      ]
    }
  }
};
