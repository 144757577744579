import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { Item } from '../models/item.model'

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.scss'],
})
export class ItemsListComponent {
  @ViewChild('search') searchElement: ElementRef;
  @Output() putItems: EventEmitter<any>;
  @Input() items: Item[];
  @Input() error: HttpErrorResponse;
  @Input() empty: Boolean;
  randomPlaceholder: string;

  constructor() {
    this.putItems = new EventEmitter<any>();
  }

  /* istanbul ignore next */
  validation(event: number, item: Item): void {
    if (event) {
      this.putItems.emit({
        application: (item.type === 'project' ? item.name : item.type === 'instance' ? item.instancesId : ''),
        state: 'running',
        environment: item.environment
      });
    }
  }

  isDisabled(item: Item) {
    if (item.state === 'pending' ||
      item.state === 'running' ||
      item.state === 'terminated' ||
      item.state === 'stopping'
    ) {
      return true;
    } else {
      return false;
    }
  }
}
