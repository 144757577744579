import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ValidateDialogComponent } from './validate-dialog/validate-dialog.component';

@Directive({
  selector: '[appValidate]'
})
export class ValidateDirective {

  @Input() message: string;
  @Output() validation: EventEmitter<boolean>;

  constructor(public dialog: MatDialog) {
    this.validation = new EventEmitter();
  }

  @HostListener('click') openDialog(): void {
    const ref = this.dialog.open(ValidateDialogComponent, {
      data: {message: this.message}
    });
    ref.afterClosed().subscribe((result: boolean) => {
      this.validation.emit(result);
    })
  }

}
