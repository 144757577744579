import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-validate-dialog',
  templateUrl: './validate-dialog.component.html',
  styleUrls: ['./validate-dialog.component.scss']
})
export class ValidateDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

}
