import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  breakpointInfMD: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    event.target.innerWidth <= 650 ? this.breakpointInfMD = true : this.breakpointInfMD = false;
  }
  constructor(
    @Inject(DOCUMENT) public document: Document,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.onResize({target: { innerWidth: window.innerWidth }});
  }

}
