<div class="header">
    <h1 class="title">Logs</h1>
    <button class="refresh-button" mat-icon-button (click)="getLogs()">
        <mat-icon>refresh</mat-icon>
    </button>
</div>

<div class="table-responsive">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <ng-container matColumnDef="LogTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date et heure</th>
            <td mat-cell *matCellDef="let element">{{ element.LogTime | date: 'short' }}</td>
        </ng-container>

        <ng-container matColumnDef="LogType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let element">{{ element.LogType }}</td>
        </ng-container>

        <ng-container matColumnDef="LogAuthor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Auteur</th>
            <td mat-cell *matCellDef="let element">{{ element.LogAuthor }}</td>
        </ng-container>

        <ng-container matColumnDef="LogComment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Commentaire</th>
            <td mat-cell *matCellDef="let element">{{ element.LogComment }}</td>
        </ng-container>

        <ng-container matColumnDef="TimeToExist">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Suppression automatique</th>
            <td mat-cell *matCellDef="let element">{{ element.TimeToExist * 1000 | date: 'short' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="5">Aucune donnée</td>
        </tr>
    </table>
</div>

<div></div>