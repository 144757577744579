<div *ngIf="loading" class="loading-container">
  <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
</div>

<div class="mat-app-background flexbox dark-mode">
  <app-header></app-header>
  <div class="mx-auto my-3">
    <mat-chip-list aria-label="Environment selection">
      <mat-chip (click)="switchAddress('https://vm-manager.vdi.aws.terega.fr')" [selected]="findEnv('vdi')"
        color="accent">VDI</mat-chip>
      <mat-chip (click)="switchAddress('https://vm-manager.las.aws.terega.fr')" [selected]="findEnv('las')"
        color="accent">LAS</mat-chip>
      <mat-chip (click)="switchAddress('https://vm-manager.sis.terega.fr')" [selected]="findEnv('sis')"
        color="accent">SIS</mat-chip>
    </mat-chip-list>
  </div>
  <div class="container mb-3">
    <router-outlet></router-outlet>
  </div>
</div>