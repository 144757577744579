import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Log } from '../models/log.model';
import { DataService } from '../services/data-service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<Log>;
  displayedColumns: string[];
  empty: boolean;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.displayedColumns = ['LogTime', 'LogType', 'LogAuthor', 'LogComment', 'TimeToExist'];
  }

  ngAfterViewInit(): void {
    this.getLogs();
  }

  getLogs(): void {
    this.dataService.getLogs().pipe(
      map(logs => {
        return logs.sort(function (a, b) {
          return new Date(b.LogTime).getTime() - new Date(a.LogTime).getTime();
        });
      })
    ).subscribe(logs => {
      this.dataSource = new MatTableDataSource(logs);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property): string | number => {
        switch (property) {
          case 'LogTime': return new Date(item.LogTime).getTime();
          default: return item[property];
        }
      };
    });
  }
}
