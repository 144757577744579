import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './services/notifications.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from './services/loading.service';
import { delay } from 'rxjs/operators';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loading: boolean;

  constructor(
    private notifications: NotificationsService,
    private snackBar: MatSnackBar,
    private loadingService: LoadingService,
    private overlay: OverlayContainer
  ) {
    this.loading = false;
    this.notifications.notification$.subscribe(notification => {
      this.snackBar.open(notification.message, 'OK', {
        duration: 2000,
        panelClass: ['mat-toolbar', `mat-${notification.color}` ]
      })
    });
  }

  ngOnInit() {
    this.listenToLoading();
    this.overlay.getContainerElement().classList.add('dark-mode');
  }

  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  findEnv(env: string): boolean {
    const address = window.location.host;
    if (address.match(env)){
      return true;
    }
  }

  switchAddress(address: string): void {
    window.location.href = address;
  }

}
