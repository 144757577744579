import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateColor'
})
export class StateColorPipe implements PipeTransform {

  transform(value: string): string {
    let result = '';

    switch (true) {
      case /unknown/.test(value):
        result = '#bdc3c7';
        break;
      case /running/.test(value):
        result = '#2ecc71';
        break;
      case /pending/.test(value):
        result = '#f1c40f';
        break;
      case /shutting-down/.test(value):
        result = '#f1c40f';
        break;
      case /stopping/.test(value):
        result = '#f1c40f';
        break;
      case /stopped/.test(value):
        result = '#e74c3c';
        break;
      case /terminated/.test(value):
        result = '#e74c3c';
        break;
      case /rebooting/.test(value):
        result = '#2980b9';
        break;
      default:
        result = '';
        break;
    }

    return result;
  }

}
