import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '@auth0/auth0-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment as env } from '../environments/environment';

import { AppComponent } from './app.component';
import { ApplicationsComponent } from './applications/applications.component';
import { HeaderComponent } from './header/header.component';
import { InstancesComponent } from './instances/instances.component';
import { StateColorPipe } from './pipes/state-color.pipe';
import { FilterOnIncludesPipe } from './pipes/filter-on-includes.pipe';
import { ItemsListComponent } from './items-list/items-list.component';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor.service';
import { HelpComponent } from './help/help.component';
import { ValidateDirective } from './validate.directive';
import { ValidateDialogComponent } from './validate-dialog/validate-dialog.component';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { LogsComponent } from './logs/logs.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    ApplicationsComponent,
    HeaderComponent,
    InstancesComponent,
    StateColorPipe,
    FilterOnIncludesPipe,
    ItemsListComponent,
    HelpComponent,
    ValidateDirective,
    ValidateDialogComponent,
    LogsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    AuthModule.forRoot(env.authConfig),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "fr-FR" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
