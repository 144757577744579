import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOnIncludes'
})
export class FilterOnIncludesPipe implements PipeTransform {

  transform(array: any[], charIncludes: string): any[] {
    const filterValue = charIncludes.toLowerCase();
    return array.filter(item => {
      return item.name.toLowerCase().includes(filterValue);
    });
  }
}
