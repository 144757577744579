<div *ngIf="items && !error">
    <mat-form-field color="accent">
        <mat-label>Filtre par nom</mat-label>
        <input matInput [placeholder]="randomPlaceholder" #search>
        <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="search.value = ''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <div class="grid">
        <mat-card *ngFor="let item of items | filterOnIncludes: search.value">
            <mat-card-header>
                <mat-card-title>
                    {{ item.name }}
                </mat-card-title>
                <span matTooltip="Tarif mensuel" matTooltipPosition="above" id="price">&#8771; {{ item.price | number:'1.0-0' }} $</span>
                <mat-card-subtitle>
                    <span *ngIf="item.environment && item.environment != 'None'" id="environment">{{ item.environment }}</span>
                    <div class="status">
                        <span [style.background-color]="item.state | stateColor" class="circle-color"></span>
                        <p [style.color]="item.state | stateColor">{{ item.state }}</p>
                    </div>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
                <div class="m-1">
                    <button mat-button appValidate
                        message="Êtes-vous sûr de vouloir démarrer le module {{ item.name }} ?" class="my-button"
                        (validation)="validation($event, item)" [disabled]="isDisabled(item)">
                        <mat-icon class="my-icon">play_arrow</mat-icon>
                        Démarrer
                    </button>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<div *ngIf="error">
    <p>{{ error?.error?.message }}</p>
</div>

<div *ngIf="empty">
    <p class="h4 text-center">Vide</p>
</div>