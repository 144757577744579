import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ApplicationsComponent } from './applications/applications.component';
import { InstancesComponent } from './instances/instances.component';
import { HelpComponent } from './help/help.component';
import { LogsComponent } from './logs/logs.component';


const routes: Routes = [
  { path: 'applications', component: ApplicationsComponent, canActivate: [AuthGuard] },
  { path: 'instances', component: InstancesComponent, canActivate: [AuthGuard] },
  { path: 'logs', component: LogsComponent, canActivate: [AuthGuard] },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'instances', pathMatch: 'full' },
  { path: '**', redirectTo: 'instances' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
