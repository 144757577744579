import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public notification$: Subject<any>;

  constructor() {
    this.notification$ = new Subject();
  }
}
