<mat-toolbar>
    <img *ngIf="!breakpointInfMD" class="logo" src="../../assets/imgs/TEREGA_Logo_Horizontal_blanc.png" alt="Logo de Teréga">
    <span id="title">VM-Manager</span>
    <span class="spacer"></span>
    
    <button *ngIf="!breakpointInfMD" mat-button class="mx-1" routerLink="instances" routerLinkActive="mat-accent">
        <mat-icon>account_box</mat-icon>
        <span>&nbsp;Mes instances</span>
    </button>
    <button *ngIf="breakpointInfMD" mat-icon-button matTooltip="Mes instances" class="mx-1" routerLink="instances" routerLinkActive="mat-accent">
        <mat-icon>account_box</mat-icon>
    </button>

    <button *ngIf="!breakpointInfMD" mat-button class="mx-1" routerLink="applications" routerLinkActive="mat-accent">
        <mat-icon>grid_view</mat-icon>
        <span>&nbsp;Applications</span>
    </button>
    <button *ngIf="breakpointInfMD" mat-icon-button matTooltip="Applications" class="mx-1" routerLink="applications" routerLinkActive="mat-accent">
        <mat-icon>grid_view</mat-icon>
    </button>

    <button *ngIf="!breakpointInfMD" mat-button class="mx-1" routerLink="logs" routerLinkActive="mat-accent">
        <mat-icon>feed</mat-icon>
        <span>&nbsp;Logs</span>
    </button>
    <button *ngIf="breakpointInfMD" mat-icon-button matTooltip="Logs" class="mx-1" routerLink="logs" routerLinkActive="mat-accent">
        <mat-icon>feed</mat-icon>
    </button>
    <button *ngIf="authService.isAuthenticated$ | async" mat-icon-button matTooltip="Déconnexion" class="mx-1"
        (click)="authService.logout({ returnTo: document.location.origin })">
        <mat-icon>logout</mat-icon>
    </button>
</mat-toolbar>