import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Item } from '../models/item.model';
import { DataService } from '../services/data-service';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent implements OnInit {
  applications: Item[];
  error: HttpErrorResponse;
  empty: Boolean;

  constructor(
    private data: DataService,
    private notifications: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.getApplications();
  }

  getApplications() {
    this.applications = undefined;
    this.empty = undefined;
    this.data.getApplications().subscribe(
      (applications: Item[]) => {
        this.error = undefined;
        this.applications = applications;
        if(applications.length == 0){
          this.empty = true
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  putApplications(informations: any) {
    this.data.putApplications(informations.application, informations.state, informations.environment).subscribe(
      () => {
        this.error = undefined;
        this.notifications.notification$.next({
          message: 'Application démarré',
          color: 'accent'
        });
        this.getApplications();
      },
      (error) => {
        this.error = error;
      }
    )
  }

}
